import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/blog/blogs-details.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Many pet owners do not know that they should brush their cat or dog's teeth. It's true that pets generally do not need their teeth brushed as frequently as humans do, but they should usually have their teeth brushed multiple times per week.`}</p>
    <p>{`Brushing your pet's teeth is a similar experience to brushing your own. You'll take a pet toothbrush, apply some toothpaste or just water, and then gently brush your pets teeth.`}</p>
    <p>{`Unless you're extremely lucky, the first few times that you brush your pet's teeth will almost certainly be unenjoyable. In a good case, your pet will lick the toothpaste off the brush and maybe chomp on the brush. Worst case: your pet will flat out refuse to have their teeth brushed. Over time, this will get easier but it's important to regularly practice brushing or your pet may never become accustomed to it.`}</p>
    <p>{`Your veterinarian may have more information for you regarding the brushing habits for your particular pet. If you have any questions, please contact your veterinarian.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      